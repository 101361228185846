@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');

:root {
  --primary-color: #20AE72;
  --secondary-color: #0A2E36;
  --website-header-height: 90px;
  --header--colour: #2f2f2f;
}

@media (max-width: 768px) {
  :root {
    --website-header-height: 60px;
  }
}

h1, h2, h3, h4, h5, h6, span, p {
  color: var(--secondary-color)
}

a, button {
    cursor: pointer;
}


#about {
//   background-color: rgba(201, 255, 232, 1)
    background: rgb(22,101,68);
    // background: linear-gradient(180deg, rgba(22,101,68,1) 0%, rgba(32,174,114,1) 100%);
}

#explore {
    background-color: rgba(201, 255, 232, 1)
} 

#upload {
  background-color: #FFEDC9;
}


.website {
  width: 100%;

  .website--navigation {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: var(--website-header-height);
    z-index: 10;
    background: white;
    display: flex;
    align-items: center;

    a {
        position: relative;

        &:after {
            content: '';
            position: absolute;
            bottom: -4px;
            left: 0;
            width: 0;
            height: 2px;
            background: black;
            transition: width 0.3s;
        }

        &.active:after {
            background: #000;
            width: 100%;
        }
    }

    .website--navigation-container {
        max-width: 1224px;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        padding: 0 16px;

        .website--navigation-logo {
            display: flex;
            align-items: center;   
        }

        ul {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 16px;
        }

        

        .website--navigation-links {
            list-style-type: none;
            padding: 0;
            margin: 0;
            display: flex;
        }
    }
  }
}

.website--navigation-buttons {
    display: flex;
    flex-direction: row;
    gap: 16px;
    font-weight: 500;
    border-radius: 8px;

    a {
        font-size: 16px;
        padding: 16px 24px;
        border-radius: 8px;
        font-weight: 500;
        text-align: center;
        align-self: center;

        &.website--navigation-sign-in {
            background: var(--secondary-color);
            color: white;
            font-size: 15px;
        }

        &.website--navigation-sign-up {
            background: var(--primary-color);
            color: white;
            font-size: 15px;
        }
    }            
}

.mobile--logo {
    display: none;
}


#home {
    background: linear-gradient(180deg, rgba(183,234,255,1) 0%, rgba(255,237,201,1) 100%);
    position: relative;
    height: auto;
    margin-top: var(--website-header-height);

    .landscape--container {
        z-index: 3;
        margin-left: auto; 
        margin-right: auto;
        width: 100%;
    }

    .cloud {
        z-index: 5;
    }

    .cloud1 {
        position: absolute;
        left: 80px;
        top: 200px;
        animation-name: cloud1Move;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        animation-delay: -2s;
    }

    .cloud2 {
        position: absolute;
        left: 180px;
        top: 40px;
        animation-name: cloud2Move;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }

    .cloud3 {
        position: absolute;
        right: 80px;
        top: 120px;
        animation-name: cloud3Move;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }

    .website-page--title {
        z-index: 4;
        position: relative;
        display: flex;
        gap: 32px;
        flex-direction: column;
        align-items: center;
        position: absolute; 
        left: 0; 
        right: 0; 
        margin-left: auto; 
        margin-right: auto; 
        top: 0;
        width: 100%;
        padding-top: 10vw;

        h1 {
            color: rgb(60, 60, 67);
            font-size: 3.75rem;
            font-style: normal;
            font-weight: 900;
            line-height: 100%; /* 128px */
            font-family: "Source Sans 3";
            text-align: center;
        }

        p{
            font-size: 24px;
            text-align: center;
        }

        a {
            display: flex;
            padding: 16px 32px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            flex: 1 0 0;
            width: auto;
            font-family: "Source Sans 3";
            transition: all 0.3s;
            background: var(--primary-color);
            border-radius: 8px;
            box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.1);
            color: white;
            font-weight: 500;
    
            &:hover {
                background: var(--secondary-color);
                
                box-shadow: 0 0 0 rgba(0,0,0,0);
            }
        }
    }
}

@keyframes cloud1Move {
  0%   {top:200px;}
  50%  {top:205px;}
  100% {top:200px;}
}

@keyframes cloud2Move {
  0%   {top:40px;}
  50%  {top:45px;}
  100% {top:40px;}
}

@keyframes cloud3Move {
  0%   {top:120px;}
  50%  {top:125px;}
  100% {top:120px;}
}

.website--section {
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    position: relative;

    .website-page--container {
        display: flex;
        align-items: center;
        max-width: 1224px;
        padding: 0 16px;
        z-index: 5;
        position: relative;

        .website-page--image-container, .website-page--text-container {
            flex: 0 0 50%;
        }

        .website-page--text-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 24px;
            padding-right: 32px;
        }
    }

    .website-page--text-container {

        h2 {
            color: var(--header--colour);
            font-family: "Source Sans 3";
            font-size: 72px;
            font-style: normal;
            font-weight: 900;
            line-height: 100%; /* 96px */
        }
    
        p {
            color: #000;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%; /* 32px */
            font-family: "Source Sans 3";
        }
    
        a {
            display: flex;
            padding: 16px 32px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            // flex: 1 0 0;
            width: auto;
            font-family: "Source Sans 3";
            transition: all 0.3s;
    
            &:hover {
                background: black;
            }
        }
    }

    .website-page--image-container {
        display: flex;
        width: 360px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
    }
}

.website-footer {
    background: var(--secondary-color);
    
    &--container {
        padding: 48px 16px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 16px;
        max-width: 1224px;
        margin: 0 auto;
    }

    &--links {
        display: flex;
        flex-direction: row;
        gap: 32px;
    }
}



@media (max-width: 940px) {
    .website--section {
        height: auto;
        padding: 32px 0;

        .website-page--container {
            flex-direction: column;

            .website-page--text-container {
                text-align: center;
                align-items: center;
                width: auto;
                padding-right: 0;
                order: 2;
                margin-top: 24px;
    
                h1 {
                    padding: 0 16px;
                    font-size: 48px;
                }
    
                h2 {
                    padding: 0 16px;
                    font-size: 36px;
                }
    
                p {
                    font-size: 20px;
                    padding: 0 16px;
                    line-height: 1.2;
                }

                .cta--button {
                    font-size: 14px;
                    padding: 8px 16px;
                }
            }
    
            .website-page--image-container {
                max-width: 360px;
                width: auto;
                padding: 0 16px;
    
                img {
                    width: 100%;
                }
            }
        }
    }
}

@media (max-width: 768px) {

    .website {
        .website--navigation {
            height: var(--website-header-height);
      
          .website--navigation-container {
      
              ul {
                display: none; 
              }
      
              .website--navigation-buttons {
                display: none; 
              }

                .website--navigation-logo {order: 1;}
                .website--navigation-links {order: 2;}
            }
        }

        .website-footer {
        
            &--container {
                flex-direction: column;
                justify-content: flex-start;
            }
        }

        #home {
            padding: 100px 0 0 0;

            .website-page--title {
                gap: 16px;
            
                h1 {
                    font-size: 32px;
                }

                p {
                    font-size: 16px;
                }
            }
    
            .cloud {
                display: none;
            }
        }

        .cta--button {
            font-size: 14px;
            padding: 8px 16px;
        }
    }

    .desktop--logo {
        display: none;
    }

    .mobile--logo {
        display: block;
    }
}


.text-white {
    color: white !important;
}